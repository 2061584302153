﻿@font-face {
    font-family: 'icomoon';
    src: url('../fonts/icomoon.eot?vb07zw');
    src: url('../fonts/icomoon.eot?vb07zw#iefix') format('embedded-opentype'), url('../fonts/icomoon.ttf?vb07zw') format('truetype'), url('../fonts/icomoon.woff?vb07zw') format('woff'), url('../fonts/icomoon.svg?vb07zw#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: inherit;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
/*
.icon-add:before {
    content: "\e900";
}

.icon-save:before {
    content: "\e917";
}
  
.icon-wait:before {
    content: "\e916";
}

.icon-menu:before {
    content: "\e915";
}

.icon-clock:before {
    content: "\e914";
}

.icon-infinity:before {
    content: "\e913";
}

.icon-raven:before {
    content: "\e912";
}

.icon-dns-management:before {
    content: "\e900";
}

.icon-download:before {
    content: "\e901";
}

.icon-edit:before {
    content: "\e902";
}

.icon-email:before, .icon-license-emails:before {
    content: "\e903";
}

.icon-invoices:before {
    content: "\e904";
}

.icon-license:before, .icon-my-licenses:before {
    content: "\e905";
}

.icon-licensekey:before {
    content: "\e906";
}

.icon-mail:before {
    content: "\e907";
}

.icon-node:before {
    content: "\e908";
}

.icon-payments:before {
    content: "\e909";
}

.icon-refresh:before {
    content: "\e90a";
}

.icon-support:before {
    content: "\e90b";
}

.icon-tick:before, .icon-checkmark:before, .icon-accept:before {
    content: "\e90c";
}

.icon-trash:before {
    content: "\e90d";
}

.icon-unavailable:before, .icon-cancel:before, .icon-remove:before {
    content: "\e90e";
}

.icon-upgrade:before {
    content: "\e90f";
}

.icon-warning:before {
    content: "\e910";
}

.icon-key:before {
    content: "\e911";
}*/

  .icon-cplusplus:before {
    content: "\e9b3";
  }
  .icon-plus:before {
    content: "\e900";
  }
  .icon-clock:before {
    content: "\e914";
  }
  .icon-wait:before {
    content: "\e916";
  }
  .icon-menu:before {
    content: "\e915";
  }
  .icon-infinity:before {
    content: "\e913";
  }
  .icon-raven:before {
    content: "\e912";
  }
  .icon-dns-management:before {
    content: "\e901";
  }
  .icon-download:before {
    content: "\e902";
  }
  .icon-edit:before {
    content: "\e903";
  }
  .icon-email:before, .icon-license-emails:before {
    content: "\e904";
  }
  .icon-invoices:before {
    content: "\e905";
  }
  .icon-license:before, .icon-my-licenses:before {
    content: "\e906";
  }
  .icon-licensekey:before {
    content: "\e907";
  }
  .icon-mail:before {
    content: "\e908";
  }
  .icon-node:before {
    content: "\e909";
  }
  .icon-payment:before, .icon-payments:before {
    content: "\e90a";
  }
  .icon-refresh:before {
    content: "\e90b";
  }
  .icon-support:before {
    content: "\e90c";
  }
  .icon-tick:before, .icon-checkmark:before, .icon-accept:before {
    content: "\e90d";
  }
  .icon-trash:before {
    content: "\e90e";
  }
  .icon-unavailable:before, .icon-cancel:before, .icon-remove:before {
    content: "\e90f";
  }
  .icon-upgrade:before {
    content: "\e910";
  }
  .icon-warning:before {
    content: "\e911";
  }
  .icon-key:before {
    content: "\e917";
  }
  .icon-save:before {
    content: "\e918";
  }
