﻿
/* SCROLLBARS */
::-webkit-scrollbar-track {
    background-color: $gray;
}

::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb {
    background-color: $gray-light;

    &:hover {
        background-color: $gray-lighter;
    }

    &:active {
        background-color: $brand-primary;
    }
}

.App {
    text-align: center;
}


.App-header {
    flex-direction: column;
}


button, a {
    cursor: pointer;
}

html, body {
    height: 100%;
    width: 100%;
    font-size: $font-size;
}

body {
    background-color: $gray-base;
    font-family: 'lato', Helvetica Neue,Helvetica,Arial,sans-serif;
    color: $gray-light;
    line-height: 1.5em;
}

#root {
    height: 100vh;
    display: flex;
    flex-direction: column;
}

a {
    color: $brand-primary;
    text-decoration: none;

    &:hover {
        color: saturate( lighten( $brand-primary, 15% ), 100% );
    }
}

input, textarea {
    border: none;
    color: $gray-light;

    &::placeholder {
        color: $gray;
        opacity: 1;
    }
}

.row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -$gutter-xs;
    margin-left: -$gutter-xs;
    [class^="col-"], [class*=" col-"] {
        flex-basis: 0;
        max-width: 100%;
        margin: $gutter-xs;
    }

    .col-sm {
        flex-grow: 1;
    }

    .col-md {
        flex-grow: 2;
    }

    .col-lg {
        flex-grow: 3;
    }
}

.icons-margin {
    width: $gutter;
    min-height: 5px;
    font-size: 21px;
    margin-right: $gutter-xs;
}

.Topbar {
    height: 20px;
    width: 100%;
    top: 0;
    left: 0;
    background-color: #262a30;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 40px 49px;
    box-sizing: border-box;
    z-index: 90;
}

.text-center {
    text-align: center;
}

.text-muted {
    color: $gray;
}

.text-lighter {
    color: $gray-lighter;
}

.text-success {
    color: $brand-success !important;
}

.text-sm {
    font-size: 12px;
}

.text-lg {
    font-size: 22px;
}

strong {
    font-weight: 800;
}

.no-margin {
    margin: 0 !important;
}

hr {
    border: none;
    border-top: 1px solid $gray-dark;
}

.editable {
    color: $brand-primary;
    min-width: 20px;
    cursor: pointer;

    &:focus {
        cursor: text;
        box-shadow: 0 2px $brand-primary;
    }
}

.flex-horizontal, .flex-vertical {
    display: flex;

    &.center {
        align-items: center;
    }
}



.flex-noshrink {
    flex-shrink: 0;
}

.flex-wrap {
    flex-wrap: wrap;
}

.flex-vertical {
    flex-direction: column;
}

.flex-grow {
    flex-grow: 1;
}

.flex-grow-2 {
    flex-grow: 2;
}

.scroll {
    flex-shrink: 1;
    overflow-y: auto;
}

h1 {
    font-size: 26px;
    line-height: 26px;
    color: $gray-lighter;
}

h1, h2, h3, h4 {
    font-weight: 700;
}

h1, h2 {
    margin-top: 0;
}

h3, h4 {
    margin-top: $gutter-sm;
}

.margin-bottom {
    margin-bottom: $gutter;
}

.margin-top {
    margin-top: $gutter;
}

.margin-left {
    margin-left: $gutter;
}

.margin-right {
    margin-right: $gutter;
}

.padding {
    padding: $gutter-sm $gutter;

    @media (min-width: $screen-md) {
        padding: $gutter $gutter-lg;
    }

    &.padding-sm {
        padding: $gutter-xs $gutter-sm;

        @media (min-width: $screen-md) {
            padding: $gutter-sm $gutter;
        }
    }

    &.padding-xs {
        padding: $gutter-xs $gutter-sm;

        @media (min-width: $screen-md) {
            padding: $gutter-xxs $gutter-xs;
        }
    }
}

.panel {
    background-color: $gray-darker;

    .panel-addon {
        background-color: $gray-base-light;
    }

    & + .panel {
        margin-top: $gutter;
    }

    &.edit-mode {
        background-color: $bg-primary;
        color: $gray-lighter;
        box-shadow: 0 0 0 2px $brand-primary inset;


        .panel-addon {
            box-shadow: 0 0 0 2px $brand-primary inset;
        }
    }
}

strong {
    color: lighten($gray-light, 15%);
}

ul, ol {
    padding-left: 0;
    list-style-position: inside;

    li {
        line-height: 1.5em;
        margin-bottom: .5em;
        display: flex;



        a {
            font-weight: bold;
            flex-grow: 1;
            text-decoration: 0;
            text-decoration: none;
        }
    }

    &.list-withIcons {
        [class^="icon-"], [class*=" icon-"] {
            margin-right: $gutter-xs
        }
    }
}

.btn-group {
    margin: -$gutter-xs/2;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;

    .btn {
        margin: $gutter-xs/2;
    }
}

.btn {
    padding: $gutter-xxs $gutter-xs;
    border: none;
    display: inline-block;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    line-height: $gutter-sm;
    text-decoration: none;

    @media (min-width: $screen-sm) {
        padding: $gutter-xs $gutter;
    }

    &.btn-sm {
        padding: $gutter-xxs $gutter-xs;
    }

    &.btn-default {
        background-color: $gray-dark;
        color: $gray-light;

        &:hover {
            background-color: lighten($gray-dark, 5%);
            color: lighten($gray-light, 5%);
        }
    }

    &.btn-primary {
        background-color: $brand-primary;
        color: $gray-lighter;
        font-weight: 800;

        &:hover {
            background-color: lighten($brand-primary, 5%);
            color: lighten($gray-lighter, 5%);
        }
      
    }
    
    &.animation{
        &:disabled {
            background-color: #C7C6C6;
            cursor: not-allowed;
            color: #888888;
        }
      
        &:not(:disabled) {
            animation: enable 0.3s linear;
            transition: transform .2s ease, background .3s ease, box-shadow .3s ease;
        }
        @keyframes enable {
            0% {
                transform: scale(1);
            }
            60% {
                transform: scale(1.1);
            }
            100% {
                transform: scale(1);
            }
        }
    }
}

.bg-primary {
    background-color: $bg-primary;
    color: $brand-primary;
}

.header {
    padding: $gutter-sm $gutter;
    display: flex;
    align-items: center;
    flex-shrink: 0;
    background-color: $gray-base-light;

    @media (min-width: $screen-sm) {
        padding: $gutter $gutter-lg;
    }

    .logo {
        display: block;

        img {
            height: 42px;
        }
    }

    .settings {
        font-size: $gutter;
        transition: color .2s $global-easing;
        color: $gray;

        &:hover {
            color: $gray-lighter;
        }
    }

    .btn-menu {
        margin-left: $gutter-xxs;

        @media (min-width: $screen-sm) {
            display: none;
        }
    }
}

.container {
    display: flex;
    flex-grow: 1;
    position: relative;
}

.menu-button {
    @media (max-width: $screen-xs-max) {
    }

    @media (min-width: $screen-sm) {
    }
}

.sidebar {
    width: $sidebar-width;
    max-width: 100vw;
    background-color: $gray-darker;
    flex-shrink: 0;
    overflow-y: auto;
    position: relative;
    flex-shrink: 0;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 1;
    transition: .2s transform cubic-bezier(0.5, 0, 0.5, 1);

    @media (max-width: $screen-sm-max) {
        transform: translateX(-100%);
        position: absolute;
        height: 100%;

        &.show {
            transform: translateX(0%);
        }
    }

    .main-menu {
        a {
            display: block;
            padding: $gutter-sm $gutter-lg;
            color: $gray;
            text-decoration: none;

            i {
                font-size: 18px;
                position: relative;
                top: 3px;
                margin-right: $gutter-sm;
            }

            &:hover {
                color: $gray-light;
            }

            &.active {
                @extend .bg-primary;
                box-shadow: inset 5px 0 0 0 $brand-primary;

                &:hover {
                    color: $gray-lighter;
                }
            }

            &.download {
                color: $color-3-2;
                font-weight: bold;

                &:hover {
                    color: lighten($color-3-2, 25%);
                }
            }
        }
    }

    .back-button {
        text-decoration: none;
    }


    .sidebar-heading {
        position: relative;

        .back-button {
            top: -13px;
            position: absolute;
            left: -$gutter-lg;
            font-size: 35px;
            width: $gutter-lg;
            height: $gutter-lg;
            text-align: center;
            line-height: $gutter-lg
        }
    }
    .DisableRowByOr{
        background-color: white;
        color: #F5F5F5;
        
    }
    .sidebar-controlls {
        position: sticky;
        top: 0;
        z-index: 1000;
        //text-align:right;
        margin-bottom: -$gutter;

        .collapse-sidebar, .back-button {
            display: block;
            background-color: $gray-dark;
            height: $gutter;
            width: $gutter;
            border: none;
            color: $gray-light;
            line-height: $gutter;
            text-align: center;
        }

        .back-button {
            position: absolute;
            height: 0;
            transition: height .2s $global-easing;
            background-color: $brand-primary;
            color: $gray-lighter;
            overflow: hidden;
        }
    }
}

.bodyOnCanvas {
    flex-grow: 1;
    padding: $gutter;
    overflow: 0;
    position: absolute;
    left: 0;
    right: 0;
    top: 107px;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    @media (max-width: $screen-sm) {
        
    }
}

.body {
    flex-grow: 1;
    padding: $gutter;
    background-color: $gray-base;
    overflow: auto;
    position: relative;
    display: flex;
    flex-direction: column;

    @media (min-width: $screen-sm) {
        padding: $gutter-lg;
    }
}
//collapse
.collapse {
    &:not(.show) {
        display: none;
    }
}

.collapsing {
    position: relative;
    height: 0;
    overflow: hidden;
    transition: height .35s ease;
}
//dropdowns
// The dropdown wrapper (`<div>`)
.dropdown {
    position: relative;
}

.dropdown-toggle::after {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    border-top: .3em solid;
    border-right: .3em solid transparent;
    border-bottom: 0;
    border-left: .3em solid transparent;
}
// The dropdown menu
.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: $zindex-dropdown;
    display: none; // none by default, but block on "open" of the menu
    float: left;
    min-width: $dropdown-min-width;
    padding: $gutter-xs 0;
    margin: $gutter-xs 0 0; // override default ul
    text-align: left; // Ensures proper alignment if parent has it changed (e.g., modal footer)
    list-style: none;
    background-color: $gray-dark;
    background-clip: padding-box;
    box-shadow: $global-box-shadow;
}

.dropdown-menu-right {
    right: 0;
    left: auto;
}
// When enabled Popper.js, reset basic dropdown position
// stylelint-disable no-duplicate-selectors
.dropdown-menu {
    &.placement-right {
        left: auto;
        right: 0;
    }
}
// Links, buttons, and more within the dropdown menu
//
// `<button>`-specific styles are denoted with `// For <button>s`
.dropdown-item {
    display: block;
    //    min-width: 100%; // For `<button>`s
    padding: $gutter-xs $gutter-sm;
    clear: both;
    color: $gray-light;
    text-align: inherit; // For `<button>`s
    white-space: nowrap; // prevent links from randomly breaking onto new lines
    background-color: transparent; // For `<button>`s
    border: 0; // For `<button>`s
    text-transform: uppercase;
    text-decoration: none;
    font-size: 12px;
    line-height: 12px;

    &.active,
    &:active {
        color: $gray-lighter;
        text-decoration: none;
    }

    &:hover {
        background-color: $gray-darker;
    }

    &.disabled,
    &:disabled {
        color: $gray;
        background-color: transparent;
    }
}

.dropdown-menu.show {
    display: block;
}


.dropdown-item-text {
    display: block;
    padding: $gutter-xs $gutter-xs;
    color: $gray-light;
}


.product-name {
    display: flex;
    flex-grow: 1;

    i {
        flex-shrink: 0;
        font-size: 30px;
        line-height: 26px;
        width: $gutter;
        margin-right: $gutter-xs;
    }

    .alias {
        font-weight: normal;
        font-size: 100% / $gutter-ratio;
        color: $gray;
        display: inline-block;
        margin-left: $gutter-xs;

        &::before {
            content: "(";
        }

        &::after {
            content: ")";
        }
    }

    h1 {
        margin: 0;
    }
}

.properties {
    margin: -$gutter;
    display: flex;
    flex-wrap: wrap;

    .properties-item-icon {
        padding: $gutter;
        display: flex;
        align-items: center;

        .icons-margin {
        }

        .property-item {
            padding: 0;
        }
    }

    .property-item {
        padding: $gutter;

        .name {
            text-transform: uppercase;
            font-size: 11px;
            font-weight: 900;
            color: $gray;
            margin-bottom: 4px;
            letter-spacing: .3pt;
        }

        .value {
            font-size: 19px;
            font-weight: 800;
        }
    }
}

.license-specs-container {
    -ms-flex-positive: 1;
    overflow-y: scroll;

    .license-specs {
        display: -ms-grid;
        display: grid;
        grid-gap: $gutter-sm $gutter;
        -ms-grid-columns: auto auto;
        grid-template-columns: auto auto;
        align-items: center;

        .name {
            text-align: right;
            font-size: 12px;
            line-height: 18px;
        }

        .value {
            text-align: left;
            line-height: 18px;

            .icon-checkmark {
                color: $color-3;
            }
        }

        i {
        }

        h4 {
            font-size: 16px;
            color: #f0f4f6;
            font-weight: 900;
            text-align: center;
            -ms-grid-column-span: 2;
            -ms-grid-column: 1;
            grid-column: 1/span 2;
        }
    }
}

.license-key {
    display: flex;

    .key-alias {
        width: 150px;
        flex-shrink: 0;
        display: block;
        color: $gray;
        background-color: $gray-darker;
        background-clip: padding-box;
        border: none;
        text-align: right;
    }

    .license-key-id {
        border-left: 1px solid $gray-dark;
    }
}

.btn-control {
    border: none;
    background-color: transparent;
    color: $brand-primary;
    font-size: 18px;

    &:hover {
        //background-color: $brand-primary;
        color: $gray-lighter;
    }
}

.form-control {
    width: 100%;
    border-bottom: 1px solid $gray-light !important;
}

.nodes-container {
    //display: grid;
    //grid-gap: $gutter;
    //grid-template-columns: auto auto auto;
    padding: $gutter-sm 0;


    input {
        background-color: transparent;
        color: $brand-primary;
        border: none;
        display: block;
    }

    .row {
        margin: 0;
        display: flex;
        width: 100%;
        transition: background-color .2s ease;

        .node-controlls {
            text-align: right;

            button {
                opacity: 0;
                transition: opacity .2s ease;
            }
        }

        .node-label, .node-domain, .node-ip, .node-actions {
            flex-grow: 1;
            flex-basis: 0;

            i+small {
                position: relative;
                top: -2px;
            }
        }

        .node-label, .node-domain, .node-ip {
            padding: $gutter-xxs $gutter;

            @media (min-width: $screen-md) {
                padding: $gutter-xs $gutter-lg;
            }
        }

        &:nth-of-type(2n) {
            .node-label, .node-domain, .node-ip, .node-actions {
                //background-color: rgba($gray-darker, .5);
            }
        }

        &:hover {
            background-color: $gray-dark;
        }

        &.edit-mode {
            background-color: $bg-primary;
            box-shadow: 0 0 0 2px $brand-primary inset;
        }

        &.disabled {
            background-color: $gray-darker;

            .node-domain, .node-ip {
                text-decoration: line-through;
                color: $gray;

                .editable {
                    color: $gray;
                }
            }
        }
    }
}

.node-label, .payments-label, .emails-label {
    text-transform: uppercase;
    font-weight: 900;
    font-size: 11px;
    color: $gray;
}

.emails-container {

    color: $gray;

    .emails-adress {
        color: $gray-light;
    }

    .row {
        display: flex;
        margin: 0;

        .emails-label, .emails-adress, .emails-added {
            flex-basis: 0;
            flex-grow: 1;
            padding: $gutter-xxs $gutter;

            @media (min-width: $screen-md) {
                padding: $gutter-xs $gutter-lg;
            }
        }

        &:nth-of-type(2n -1) {
            background-color: rgba($gray-darker, .5);
        }
    }
}

.emails-actions, .node-actions {
    flex-basis: 0;
    flex-grow: 1;
    padding: 0 $gutter-lg;
    display: flex;

    button, a {
        display: block;
        border: none;
        background-color: transparent;
        color: $brand-primary;
        padding: $gutter-xs;
        line-height: 21px;
        //.bg-primary;
        i {
            line-height: 21px;
        }

        small {
            position: relative;
            top: -3px;
        }

        &:hover {
            color: lighten($brand-primary, 20%);
        }
    }
}

.payments-container {
    color: $gray;

    .payments-name {
        color: $gray-light;
    }

    .row {
        display: flex;
        margin: 0;

        .payments-label, .payments-name, .payments-date, .payments-amount, .payments-invoice {
            flex-basis: 0;
            flex-grow: 1;
            padding: $gutter-xxs $gutter;

            @media (min-width: $screen-md) {
                padding: $gutter-xs $gutter-lg;
            }
        }

        &:nth-of-type(2n) {
            background-color: rgba($gray-darker, .5);

        }
    }
}

.login-center {
    align-self: center;
    width: 320px;
    text-align: center;

    textarea, input {
        background-color: $gray-darker;
        padding: $gutter-sm;
        width: 100%;
        margin-bottom: $gutter;
    }
}

.copyright {
    align-self: center;
}

.copyrightOnCanvas {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    padding: $gutter-xs;
}
.markIP{
    color: #d7325a;
    font-size: 15px;
}
canvas {
    width: 100%;
    height: 85%;
}
.test{
    position: relative;
    left: 20px;
}
.saveChangesButton {
    //display: table;
    background-color: $brand-primary;
    line-height: 20px;
    color: $gray-lighter;
    
    border:none;
    cursor: pointer;
    transition: transform .2s ease, background .3s ease, box-shadow .3s ease;

    span {
        display: inline-block;
        vertical-align: top;
        width: 20px;
        height: 20px;
        margin: 0;
        position: relative;
        overflow: hidden;
        &:before {
            content: '';
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            position: absolute;
            background: $brand-primary;
            border-radius: 50%;
            transform: scale(0);
            transition: all .3s ease, background .3s ease;
        }
        i {
            position: absolute;
            width: 12px;
            height: 12px;

           
            z-index: 1;
            &:nth-child(1) {
                width: 20px;
                height: 20px;
                top: 0;
                left: 0;
                fill: none;
                margin: 0;
                stroke: #fff;
                stroke-width: 1px;
                stroke-dashoffset: 47.124 * 2;
                stroke-dasharray: 47.124;
                opacity: 1;
           
            }
            &:nth-child(2) {
                fill: $brand-primary;
                opacity: 0;
            }
            &:nth-child(3) {
              //  left: 50%;
              margin: 0;
              opacity: 0;
            }
            &:nth-child(4) {
                position: initial;
                opacity: 0;
            }
        }
    }
 
    &.loading {
        cursor: not-allowed;
        span {
            transition: background .1s ease 0s;
            i {
                &:nth-child(1) {
                 animation: moveUpFirstItem 1s forwards;
                }
            }
        }
        ul {
            transform: rotateX(90deg);
        }
        &.done {
            background: #3FDC75;
            box-shadow: 0 4px 20px rgba(#3FDC75, .15);
            span {
                transition: background .1s ease 0s;
                &:before {
                    transform: scale(0);
                }
                i {
                    &:nth-child(3) {
                        animation: moveUpthirdItem 0.5s forwards;
                    }
                }
            }
            ul {
                transform: rotateX(180deg);
            }
        }
        &.error {
            span {
                transition: background .1s ease 0s;
                &:before {
                    transform: scale(0);
                }
                i {
                    &:nth-child(4) {
                        animation: moveUpthirdItem 0.5s forwards;
                    }
                }
            }
            ul {
                transform: rotateX(270deg);
            }
        }
    }
    ul {
        padding: 0;
        margin: 0;
        list-style: none;
        height: 20px;
        width: 95px;
        display: inline-block;
        vertical-align: top;
        text-align: center;
        position: relative;
        transform-style: preserve-3d;
        transition: transform .3s ease;
      
        li {
            --rotateX: 0deg;
            backface-visibility: hidden;
            width: 100%;
            position: absolute;
            transform-origin: 50% 50%;
            transform: rotateX(var(--rotateX)) translateZ(10px);
            
            &:nth-child(2) {
                //left: 20%;
                --rotateX: -90deg;
            }
            &:nth-child(3) {
              //left: 10%;
                --rotateX: -180deg;
            }
            &:nth-child(4) {
               // left: 20%;
                  --rotateX: -270deg;
              }
        }
    }
    
@keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @keyframes moveUpfourthItem {
    0% {
        opacity:0;
      }
      100% {
          opacity:1;
      }
  }
  @keyframes moveUpthirdItem {
    0% {
      opacity:0;
    }
    100% {
        opacity:1;
    }
  }
  @keyframes moveUpFirstItem {
    0% {
      opacity:1;
      transform: translateY(0)
    }
    100% {
        opacity:0;
        transform: translateY(-20px)
    }
  }
  @keyframes secondItemShow {
    0% {
      opacity:0.5;
     
    }
    100% {
        opacity:0.5;
       
    }
  }
  @media (min-width: $screen-sm) {
    padding: $gutter-xs $gutter;
}
}

.ErrorModal {
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%) translateY(-100%);
    z-index: $zindex-error;
    background-color: $brand-primary;
    color: $gray-lighter;
    min-width: 200px;
    text-align: center;
    padding: $gutter-sm;
    box-sizing: border-box;
    font-size: 0.875rem;
    font-family: inherit;
    font-weight: 400;
    font-size: inherit;
    line-height: 1.5;
    transition: all 0.5s cubic-bezier(0.8, 0, 1, 0.5);
    
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;

    &.active {
        transform: translateX(-50%) translateY(50px);
        opacity: 1;
        transition: all 0.5s cubic-bezier(0, 0.8, 0.5, 1);
        box-shadow: 0px 5px 10px 0 $gray-base; 
    }
    
@media (min-width: 600px) {
    .Modal {
        width: 500px;
        left: calc(50% - 250px);
    }
}
}

.tooltip {
    position: relative;
    display: inline-block;
  
  
   .tooltiptext {
    visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  top: -15px;
  left: 105%;
    transition: opacity 0.3s;
  }
  
   .tooltiptext::after {
    content: " ";
    position: absolute;
    top: 50%;
    right: 100%; /* To the left of the tooltip */
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent black transparent transparent;
   
  }
  
  &:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }
}
.specialButton span {
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.5s;
 
  }
  .specialButton span:after {
    content: "»";
    position: absolute;
    opacity: 0;
    top: -2px;
    right: -10px;
    transition: 0.5s;
    font-size: 1.5em;
  }
  
  
  .specialButton:hover span {
    padding-right: 20px;
  }
  
  .specialButton:hover span:after {
    opacity: 1;
    right: 0;
  }

.removeMargin{
    margin: 0;
}

  
  
  
